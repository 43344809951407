.about-us {
    margin-top: 1rem;
    .container {
        .wrapper {
            .title {
                h1 {
                    font-family: "Playfair Display", serif;
                    text-align: center;
                    font-size: 1.2rem;
                    @media (min-width: 1024px) {
                        font-size: 1.4rem;
                        text-align: start;
                    }
                }
            }
            .about-me-main {
                .image {
                    display: flex;
                    justify-content: center;
                    margin-top: 2rem;
                    flex: 1;
                    img {
                        display: block;
                        max-width: 100%;
                        height: 100%;
                    }
                }
                .about-me-text {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    margin-top: 1rem;
                    font-family: "Montserrat", sans-serif;
                    font-weight: 400;
                    p {
                        font-size: 0.9rem;
                    }
                    .bold {
                        font-weight: 700;
                        font-size: 1.2rem;
                    }
                }
                @media (min-width: 1024px) {
                    display: flex;
                    align-items: center;
                    .about-me-text {
                        p {
                            font-size: 1.1rem;
                        }
                        .bold {
                            font-size: 1.5rem;
                        }
                    }
                }
            }
        }
    }
}
