.pwd-by {
    padding: 0.2rem 1rem;
    background-color: #10101a;
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (min-width: 1024px) {
            justify-content: flex-end;
        }
        svg {
            width: 150px;
        }
    }
}
