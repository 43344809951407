.nav-bar {
    position: fixed;
    z-index: 999;
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    transition: 0.2s background-color, 0.2s box-shadow;
    .container {
        .wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .logo {
                display: flex;
                svg {
                    height: 50px;
                    width: fit-content;
                }
                .desktop {
                    display: none;
                }
                .tablet-white,
                .tablet-colored {
                    display: none;
                }

                @media (min-width: 768px) {
                    .mobile {
                        display: none;
                    }
                    .tablet-white,
                    .tablet-colored {
                        display: block;
                    }
                }
                @media (min-width: 1024px) {
                    .mobile {
                        display: none;
                    }
                    .desktop {
                        display: block;
                    }
                    .tablet-white,
                    .tablet-colored {
                        display: none;
                    }
                }
            }
            .mobile-nav-btn {
                @media (min-width: 1024px) {
                    display: none;
                }
                svg {
                    width: 30px;
                    height: 30px;
                }
            }
            .nav-btns {
                display: none;
                @media (min-width: 1024px) {
                    display: flex;
                }
                gap: 2rem;
                a {
                    text-decoration: none;
                    button {
                        min-height: 35px;
                        background: none;
                        border: none;
                        padding: 0.2rem 1rem;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-family: "Inter", sans-serif;
                        font-weight: 600;
                        cursor: pointer;
                    }
                }
                .divider {
                    width: 1px;
                    background-color: #000;
                }
                .call-to-action {
                    background-color: #4da57b;
                    color: white;
                    border-radius: 5px;

                    svg {
                        width: 20px;
                        height: 20px;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}

.active {
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
