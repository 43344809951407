.footer {
    margin-top: 2rem;
    .container {
        .wrapper {
            .top {
                display: flex;
                gap: 20px;
                flex-direction: column;
                @media (min-width: 1024px) {
                    flex-direction: row;
                }

                .column {
                    flex: 1;

                    &:nth-of-type(1) {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        svg {
                            width: 200px;
                            @media (min-width: 1024px) {
                                width: 250px;
                            }
                        }
                    }

                    .title {
                        h3 {
                            font-family: "Playfair Display", serif;
                            font-size: 1.2rem;
                            text-align: center;
                            @media (min-width: 1024px) {
                                text-align: left;
                            }
                        }
                    }
                    .main {
                        margin-top: 1rem;
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        .row {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            text-align: center;
                            @media (min-width: 1024px) {
                                flex-direction: row;
                                gap: 10px;
                                text-align: initial;
                            }
                            svg {
                                height: 23px;
                                width: 23px;
                            }
                            a,
                            span {
                                font-family: "Montserrat", sans-serif;
                                font-weight: 500;
                                font-size: 0.8rem;
                                display: block;
                            }
                            a {
                                color: black;
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
        }
    }
    .bottom {
        padding: 0.4rem;
        font-size: 0.8rem;
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #4da57b;
        font-family: "Montserrat", sans-serif;
        font-weight: normal;
        color: white;
    }
}
