.global-markets {
    .container {
        .wrapper {
            .title {
                h1 {
                    text-align: center;
                    font-family: "Playfair Display", serif;
                    font-size: 1.2rem;
                    @media (min-width: 1024px) {
                        text-align: start;
                        font-size: 1.4rem;
                    }
                }
            }
            .global-markets-main {
                display: grid;
                grid-template-columns: 1fr;
                @media (min-width: 1024px) {
                    grid-template-columns: 1fr 1fr;
                }
                gap: 10px;
                margin-top: 1rem;
                .market {
                    display: flex;
                    gap: 10px;
                    padding: 0.5rem;
                    align-items: center;
                    border: 3px solid #4da57b;
                    .flag {
                        width: 40px;
                        img {
                            display: block;
                            max-width: 100%;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                    .market-name {
                        flex: 1;
                        font-family: "Montserrat", sans-serif;
                        font-weight: 500;
                        font-size: 0.8rem;
                        letter-spacing: 2px;
                    }
                }
            }
            .show-more {
                margin-top: 20px;
                display: flex;
                justify-content: center;
                button {
                    font-family: "Montserrat", sans-serif;
                    background: none;
                    border: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
