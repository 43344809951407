@import url("https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Montserrat:wght@400;500;700&family=Playfair+Display:wght@800&family=Poppins:wght@700&display=swap");
.hero {
  height: clamp(600px, 100vh, 800px);
  position: relative;
}
.hero .background {
  right: 0;
  bottom: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  filter: brightness(0.6);
  z-index: -1;
}
@media (min-width: 1024px) {
  .hero .background {
    height: 85%;
    width: 68%;
  }
}
.hero .background figure {
  height: 100%;
}
.hero .background figure img {
  display: block;
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hero .container .wrapper .landing-text h1 {
  margin-top: 9rem;
  font-size: 2.4rem;
  color: #6ee7ad;
  font-family: "Playfair Display", serif;
}
@media (min-width: 768px) {
  .hero .container .wrapper .landing-text h1 {
    margin-top: 10rem;
  }
}
@media (min-width: 1024px) {
  .hero .container .wrapper .landing-text h1 {
    max-width: 500px;
    font-size: 3rem;
    color: #ffb8b8;
    mix-blend-mode: exclusion;
  }
}
.hero .container .wrapper .landing-text span {
  display: block;
  padding-top: 1rem;
  color: #fffefc;
  font-family: "Montserrat", serif;
  font-weight: 500;
  letter-spacing: 4px;
}
@media (min-width: 1024px) {
  .hero .container .wrapper .landing-text span {
    color: #000;
  }
}
.hero .container .wrapper .landing-buttons {
  margin-top: 1.5rem;
  display: flex;
  gap: 10px;
  flex-direction: column;
  max-width: 200px;
}
@media (min-width: 1024px) {
  .hero .container .wrapper .landing-buttons {
    max-width: 320px;
  }
}
.hero .container .wrapper .landing-buttons a {
  text-decoration: none;
}
.hero .container .wrapper .landing-buttons a button {
  width: 100%;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  color: #fffefc;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  letter-spacing: 2px;
  font-size: 12px;
}
.hero .container .wrapper .landing-buttons a button.about-btn {
  background: #4da57b;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.hero .container .wrapper .landing-buttons a button.contact-btn {
  background: #fffefc;
  border: 1px solid #f0e47b;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #000;
}
@media (min-width: 1024px) {
  .hero .container .wrapper .landing-buttons a button span {
    width: 100%;
  }
}

.nav-bar {
  position: fixed;
  z-index: 999;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  transition: 0.2s background-color, 0.2s box-shadow;
}
.nav-bar .container .wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav-bar .container .wrapper .logo {
  display: flex;
}
.nav-bar .container .wrapper .logo svg {
  height: 50px;
  width: fit-content;
}
.nav-bar .container .wrapper .logo .desktop {
  display: none;
}
.nav-bar .container .wrapper .logo .tablet-white,
.nav-bar .container .wrapper .logo .tablet-colored {
  display: none;
}
@media (min-width: 768px) {
  .nav-bar .container .wrapper .logo .mobile {
    display: none;
  }
  .nav-bar .container .wrapper .logo .tablet-white,
.nav-bar .container .wrapper .logo .tablet-colored {
    display: block;
  }
}
@media (min-width: 1024px) {
  .nav-bar .container .wrapper .logo .mobile {
    display: none;
  }
  .nav-bar .container .wrapper .logo .desktop {
    display: block;
  }
  .nav-bar .container .wrapper .logo .tablet-white,
.nav-bar .container .wrapper .logo .tablet-colored {
    display: none;
  }
}
@media (min-width: 1024px) {
  .nav-bar .container .wrapper .mobile-nav-btn {
    display: none;
  }
}
.nav-bar .container .wrapper .mobile-nav-btn svg {
  width: 30px;
  height: 30px;
}
.nav-bar .container .wrapper .nav-btns {
  display: none;
  gap: 2rem;
}
@media (min-width: 1024px) {
  .nav-bar .container .wrapper .nav-btns {
    display: flex;
  }
}
.nav-bar .container .wrapper .nav-btns a {
  text-decoration: none;
}
.nav-bar .container .wrapper .nav-btns a button {
  min-height: 35px;
  background: none;
  border: none;
  padding: 0.2rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  cursor: pointer;
}
.nav-bar .container .wrapper .nav-btns .divider {
  width: 1px;
  background-color: #000;
}
.nav-bar .container .wrapper .nav-btns .call-to-action {
  background-color: #4da57b;
  color: white;
  border-radius: 5px;
}
.nav-bar .container .wrapper .nav-btns .call-to-action svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.active {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.business-collabs {
  background-color: #f5f5f5;
  padding: 1rem;
}
.business-collabs .container .wrapper .title {
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 2px;
  padding: 0.2rem 0 1rem 0;
}
.business-collabs .container .wrapper .businesses {
  overflow: hidden;
}
.business-collabs .container .wrapper .businesses .track {
  display: flex;
  width: 2880px;
  animation: scroll 60s infinite linear;
}
.business-collabs .container .wrapper .businesses .track .business-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
}
.business-collabs .container .wrapper .businesses .track .business-logo img {
  display: block;
  max-width: 100%;
}

@keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-1440px);
  }
}
.about-us {
  margin-top: 1rem;
}
.about-us .container .wrapper .title h1 {
  font-family: "Playfair Display", serif;
  text-align: center;
  font-size: 1.2rem;
}
@media (min-width: 1024px) {
  .about-us .container .wrapper .title h1 {
    font-size: 1.4rem;
    text-align: start;
  }
}
.about-us .container .wrapper .about-me-main .image {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  flex: 1;
}
.about-us .container .wrapper .about-me-main .image img {
  display: block;
  max-width: 100%;
  height: 100%;
}
.about-us .container .wrapper .about-me-main .about-me-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 1rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}
.about-us .container .wrapper .about-me-main .about-me-text p {
  font-size: 0.9rem;
}
.about-us .container .wrapper .about-me-main .about-me-text .bold {
  font-weight: 700;
  font-size: 1.2rem;
}
@media (min-width: 1024px) {
  .about-us .container .wrapper .about-me-main {
    display: flex;
    align-items: center;
  }
  .about-us .container .wrapper .about-me-main .about-me-text p {
    font-size: 1.1rem;
  }
  .about-us .container .wrapper .about-me-main .about-me-text .bold {
    font-size: 1.5rem;
  }
}

.global-markets .container .wrapper .title h1 {
  text-align: center;
  font-family: "Playfair Display", serif;
  font-size: 1.2rem;
}
@media (min-width: 1024px) {
  .global-markets .container .wrapper .title h1 {
    text-align: start;
    font-size: 1.4rem;
  }
}
.global-markets .container .wrapper .global-markets-main {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  margin-top: 1rem;
}
@media (min-width: 1024px) {
  .global-markets .container .wrapper .global-markets-main {
    grid-template-columns: 1fr 1fr;
  }
}
.global-markets .container .wrapper .global-markets-main .market {
  display: flex;
  gap: 10px;
  padding: 0.5rem;
  align-items: center;
  border: 3px solid #4da57b;
}
.global-markets .container .wrapper .global-markets-main .market .flag {
  width: 40px;
}
.global-markets .container .wrapper .global-markets-main .market .flag img {
  display: block;
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.global-markets .container .wrapper .global-markets-main .market .market-name {
  flex: 1;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 0.8rem;
  letter-spacing: 2px;
}
.global-markets .container .wrapper .show-more {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.global-markets .container .wrapper .show-more button {
  font-family: "Montserrat", sans-serif;
  background: none;
  border: none;
}
.global-markets .container .wrapper .show-more button:hover {
  text-decoration: underline;
}

.technical-services {
  padding-bottom: 2rem;
}
.technical-services .container .wrapper .title h1 {
  font-size: 1.2rem;
  font-family: "Playfair Display", serif;
  text-align: center;
  margin-top: 1rem;
}
@media (min-width: 1024px) {
  .technical-services .container .wrapper .title h1 {
    font-size: 1.4rem;
    text-align: start;
  }
}
.technical-services .container .wrapper .tech-serv-main {
  margin-top: 1rem;
}
.technical-services .container .wrapper .tech-serv-main .tech-infos .image {
  max-width: 90%;
  max-height: 100%;
  margin: 0 auto;
  flex-basis: 50%;
}
.technical-services .container .wrapper .tech-serv-main .tech-infos .image figure {
  height: 100%;
  width: 100%;
}
.technical-services .container .wrapper .tech-serv-main .tech-infos img {
  display: block;
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 1/1;
}
.technical-services .container .wrapper .tech-serv-main .tech-infos ul {
  position: relative;
  top: -50px;
  flex-basis: 50%;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 500;
  padding: 1rem;
  background: #fffefc;
  border: 2px solid #f0e47b;
  box-shadow: 5px 5px 0px #4da57b;
  width: 100%;
  padding-left: 2rem;
  align-self: stretch;
}
@media (min-width: 1024px) {
  .technical-services .container .wrapper .tech-serv-main .tech-infos ul {
    font-size: 15px;
  }
}
@media (min-width: 768px) {
  .technical-services .container .wrapper .tech-serv-main .tech-infos {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .technical-services .container .wrapper .tech-serv-main .tech-infos ul {
    position: static;
  }
}

.products .container .wrapper .title h1 {
  font-size: 1.2rem;
  font-family: "Playfair Display", serif;
  text-align: center;
}
@media (min-width: 1024px) {
  .products .container .wrapper .title h1 {
    font-size: 1.4rem;
    text-align: left;
  }
}
.products .container .wrapper .products-main {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  margin-top: 10px;
}
@media (min-width: 1024px) {
  .products .container .wrapper .products-main {
    grid-template-columns: 1fr 1fr;
  }
}
.products .container .wrapper .products-main .product-box {
  padding: 1rem;
  font-family: "Montserrat", sans-serif;
  background: #fffefc;
  border: 2px solid #4da57b;
}
.products .container .wrapper .products-main .product-box .product-title h3 {
  font-weight: 700;
  letter-spacing: 2px;
  font-size: 1rem;
}
.products .container .wrapper .products-main .product-box ul {
  margin-top: 0.5rem;
  font-size: 0.9rem;
  padding-left: 2rem;
}
.products .container .wrapper .products-main .product-box ul li {
  font-weight: 500;
}

.contact .container .wrapper .title h1 {
  font-family: "Playfair Display", serif;
  font-size: 1.2rem;
  text-align: center;
}
@media (min-width: 1024px) {
  .contact .container .wrapper .title h1 {
    font-size: 1.4rem;
    text-align: left;
  }
}
.contact .container .wrapper .contact-main {
  margin-top: 1rem;
  border: 2px solid #4da57b;
  padding: 1rem;
  position: relative;
  overflow: hidden;
}
.contact .container .wrapper .contact-main .logo {
  position: absolute;
  z-index: -1;
  bottom: 0;
  right: -100px;
  width: 400px;
  height: 400px;
}
@media (min-width: 1024px) {
  .contact .container .wrapper .contact-main .logo {
    right: 0;
  }
}
.contact .container .wrapper .contact-main input {
  background: none;
  width: 100%;
  border: none;
  border-bottom: 2px solid #4da57b;
  padding: 1rem 0;
  font-family: "Montserrat", sans-serif;
}
.contact .container .wrapper .contact-main input:focus {
  outline: none;
}
.contact .container .wrapper .contact-main textarea {
  background: none;
  font-family: "Montserrat", sans-serif;
  border: none;
  border-bottom: 2px solid #4da57b;
  margin-top: 1rem;
  width: 100%;
  resize: none;
}
.contact .container .wrapper .contact-main textarea:focus {
  outline: none;
}
.contact .container .wrapper .contact-main .title {
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  letter-spacing: 2px;
}
@media (min-width: 1024px) {
  .contact .container .wrapper .contact-main .title {
    font-size: 1.2rem;
  }
}
.contact .container .wrapper .contact-main .support-text {
  display: block;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
  font-size: 0.9rem;
  margin-top: 0.4rem;
}
.contact .container .wrapper .contact-main form {
  margin-top: 1rem;
  max-width: 600px;
  margin: 0 auto;
}
@media (min-width: 1024px) {
  .contact .container .wrapper .contact-main form .row-breaking {
    display: flex;
    gap: 10px;
  }
}
.contact .container .wrapper .contact-main form .submitter {
  margin-top: 20px;
}
.contact .container .wrapper .contact-main form .submitter button {
  border: none;
  padding: 0.5rem 3rem;
  display: block;
  color: white;
  margin: 0 auto;
  background-color: #4da57b;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
}

.footer {
  margin-top: 2rem;
}
.footer .container .wrapper .top {
  display: flex;
  gap: 20px;
  flex-direction: column;
}
@media (min-width: 1024px) {
  .footer .container .wrapper .top {
    flex-direction: row;
  }
}
.footer .container .wrapper .top .column {
  flex: 1;
}
.footer .container .wrapper .top .column:nth-of-type(1) {
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer .container .wrapper .top .column:nth-of-type(1) svg {
  width: 200px;
}
@media (min-width: 1024px) {
  .footer .container .wrapper .top .column:nth-of-type(1) svg {
    width: 250px;
  }
}
.footer .container .wrapper .top .column .title h3 {
  font-family: "Playfair Display", serif;
  font-size: 1.2rem;
  text-align: center;
}
@media (min-width: 1024px) {
  .footer .container .wrapper .top .column .title h3 {
    text-align: left;
  }
}
.footer .container .wrapper .top .column .main {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.footer .container .wrapper .top .column .main .row {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
@media (min-width: 1024px) {
  .footer .container .wrapper .top .column .main .row {
    flex-direction: row;
    gap: 10px;
    text-align: initial;
  }
}
.footer .container .wrapper .top .column .main .row svg {
  height: 23px;
  width: 23px;
}
.footer .container .wrapper .top .column .main .row a,
.footer .container .wrapper .top .column .main .row span {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 0.8rem;
  display: block;
}
.footer .container .wrapper .top .column .main .row a {
  color: black;
  text-decoration: none;
}
.footer .bottom {
  padding: 0.4rem;
  font-size: 0.8rem;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #4da57b;
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
  color: white;
}

.mobile-nav {
  position: fixed;
  background-color: white;
  height: 100%;
  width: 80%;
  z-index: 999999;
  padding: 2rem 0.5rem;
  transition: 0.2s transform cubic-bezier(0.215, 0.61, 0.355, 1);
  right: 0;
  transform: translateX(105%);
  box-shadow: -2px 2px 5px 0px rgba(168, 168, 168, 0.75);
  -webkit-box-shadow: -2px 2px 5px 0px rgba(168, 168, 168, 0.75);
  -moz-box-shadow: -2px 2px 5px 0px rgba(168, 168, 168, 0.75);
}
.mobile-nav .container .wrapper .close-btn {
  display: flex;
  justify-content: flex-end;
}
.mobile-nav .container .wrapper .close-btn button {
  border: none;
  background: none;
  font-family: "Montserrat", sans-serif;
  color: red;
  text-decoration: underline;
  font-weight: bolder;
}
.mobile-nav .container .wrapper .main-list {
  list-style-type: none;
}
.mobile-nav .container .wrapper .main-list a {
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  color: #10101a;
}
.mobile-nav .container .wrapper .main-list a li {
  padding: 1.5rem 0;
  width: 100%;
  border-bottom: 1px solid #dedede;
}

.active-nav {
  transform: translateX(0);
}

.pwd-by {
  padding: 0.2rem 1rem;
  background-color: #10101a;
}
.pwd-by .wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 1024px) {
  .pwd-by .wrapper {
    justify-content: flex-end;
  }
}
.pwd-by .wrapper svg {
  width: 150px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding: 7rem;
}

a {
  cursor: pointer;
}

.container {
  max-width: 1100px;
  padding: 0.5rem 1rem;
  margin: 0 auto;
}

.darken {
  filter: brightness(0.9);
}

body {
  background-color: #fffefc;
}

